import JustTextBlock from "../../components/JustTextBlock";
import Main from '../../components/Main';
import InformationBlock from "../../components/VeteransUp/InformationBlock";
import PhotoTextBlock from "../../components/elements/PhotoTextBlock";


const VeteranUp = () => {


    return <>

        <Main
            backgroundImage="/media/bg_3.jpg"
            title="Veterans UP"
            subtitle="Кар'єрний проєкт для військових, ветеранів і їхніх сімей у рекламній сфері"
        />

        <JustTextBlock
            title='Про навчання'
            content={`<p className="text-[20px] font-medium text-[#262626] mb-6 leading-[30px]">
            Ініціатива розрахована на військових, ветеранів і їхніх сімей, які прагнуть отримати навички з медійних дисциплін, щоб надалі розвиватися у сфері реклами
та комунікацій. Програма триває 2 місяці, комбінує лекції та практичні семінари,
а також нетворкінг, живі зустрічі та менторство стосовно працевлаштування. 
        </p>`} />

        <div className="space-y-12">
            <PhotoTextBlock
                title='Структура проєкту'
                content={`<p className=" mb-8 w-full text-base text-[#262626] leading-[24px] font-medium">
            Veterans Up – це інтенсивний освітній курс за <b>трьома напрямками:</b><br/><br/>

            <b>PPC</b> – запуск, ведення, аналіз й оптимізація рекламних кампаній на маркетингових платформах.<br/>
            <b>SEO</b> – пошукова оптимізація і виведення сайту в топові позиції пошукових систем. <br/>
            <b>Account менеджмент</b> – супровід довірених брендів клієнта, розробка й реалізація комплексних медіапроєктів. 
            </p>`}
                img='/media/veteransup/block1.jpg'
            />

            <PhotoTextBlock
                reverse={true}
                title='Формат навчання'
                content={`<p className=" mb-8 w-full text-base text-[#262626] leading-[24px] font-medium">
                Передбачає <b>змішану форму навчання</b> – лекції у записі, практичні вебінари в прямому ефірі, зворотній зв’язок і підтримку від менторів, а також оффлайн нетворкінг. Окрім <b>спеціалізованих лекцій</b>, курс надає можливість отримати знання із <b>базових медійних дисциплін</b>, зокрема:<br/>
                – екосистема рекламного ринку України<br/>
                – як влаштована індустрія всередині агенцій<br/>
                – медійна стратегія та медіапланування<br/>
                – ефективний Client Service
            </p>`}
                img='/media/veteransup/block2.jpg'
            />

            <PhotoTextBlock
                title='Вимоги до кандидата'
                content={`<p className=" mb-8 w-full text-base text-[#262626] leading-[24px] font-medium">
                1. Базові цифрові навички <br/>
                2. Зацікавленість у сфері реклами та комунікацій<br/>
                3. Готовність сумлінно виконувати задачі курсу згідно з програмою                
            </p>`}
                img='/media/veteransup/block3.jpg'
            />

            <PhotoTextBlock
                reverse={true}
                title='Що кандидат отримає
                по завершенню'
                content={`<p className=" mb-8 w-full text-base text-[#262626] leading-[24px] font-medium">
                Можливість працевлаштовуватися на junior позиції за напрямками PPC, SEO,<br/>
                account менеджмент в рекламних агенціях і маркетингових відділах компаній-рекламодавців.                 
            </p>`}
                img='/media/veteransup/block4.jpg'
            />
        </div>

        <InformationBlock />
    </>
}

export default VeteranUp;