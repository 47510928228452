import { ReactComponent as In } from "../components/icons/linkedin.svg";
import { ReactComponent as Youtube } from "../components/icons/youtube.svg";
import { ReactComponent as Instagram } from "../components/icons/instagram.svg";
import { ReactComponent as Facebook } from "../components/icons/facebook.svg";

const Footer = () => {
    return (
        <footer className="bg-black text-white py-8">
            <div className="container max-w-[1180px] mx-auto px-12 space-y-8 md:space-y-0 grid grid-cols-1 md:grid-cols-2">
                <div className="space-y-4">
                    <h2 className="text-xl font-bold mb-2">Dentsu Ukraine</h2>
                    <p>HR Team: <br /> <a href="mailto:hr@dentsu.com.ua ">hr@dentsu.com.ua </a></p>
                    <p>PR Team: <br /> <a href="mailto:pr@dentsu.com.ua">pr@dentsu.com.ua</a></p>
                </div>
                <div>
                    <p>вул. Сім’ї Прахових, 58/10 <br /> Київ, 01033, Україна</p>
                    {/* Иконки социальных сетей */}
                    <div className="flex mt-4">
                        {/* Замените `PathToIcon` на фактический путь к вашим иконкам */}
                        <a href="https://www.linkedin.com/company/dentsu-ukraine/" className="mr-2 w-11 h-11 flex items-center justify-center bg-white rounded-full transition hover:opacity-50">
                            {/* SVG или <img> для LinkedIn */}
                            <In className="fill-black h-5" />
                        </a>
                        <a href="https://www.facebook.com/dentsuaegisnetworkua/" className="mr-2 w-11 h-11 flex items-center justify-center bg-white rounded-full transition hover:opacity-50">
                            {/* SVG или <img> для Facebook */}
                            <Facebook className="fill-black h-5" />
                        </a>
                        <a href="https://www.youtube.com/channel/UCsj6sXxCk8HAMIZffE1dEuQ" className="mr-2 w-11 h-11 flex items-center justify-center bg-white rounded-full transition hover:opacity-50">
                            {/* SVG или <img> для Twitter */}
                            <Youtube className="fill-black h-5" />
                        </a>
                        <a href="https://www.instagram.com/dentsu.ukraine/" className="mr-2 w-11 h-11 flex items-center justify-center bg-white rounded-full transition hover:opacity-50">
                            {/* SVG или <img> для Instagram */}
                            <Instagram className="fill-black h-5" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
