import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import JustTextBlock from "../../components/JustTextBlock";
import Main from '../../components/Main';
import articlesData from '../../../news/news.json';
import { useLanguage } from "../../../contexts/LanguageContext";
import parse from 'html-react-parser';


const Articles = () => {
    // get params "url"
    const { url } = useParams();
    const [article, setArticle] = useState(null);
    const { language } = useLanguage();
    useEffect(() => {
        // Найти статью по slug
        const foundArticle = articlesData.articles.find(article => article.slug === url);
        setArticle(foundArticle);
    }, [url]);


    return (
        <div className="articles">
            <div className="news-header">

                <Main
                    title="Veterans UP"
                    subtitle="Об’єднуємо досвід корпоративного сектору"
                    backgroundImage="/media/business-practices.jpg"
                />
            </div>
            {!article && <div className="container mx-auto max-w-[1180px] text-center py-12">
                <h1 className="text-2xl">Стаття не знайдена</h1>
                <p className="text-2xl font-bold">404</p>
            </div>}

            {article && <div className="container mx-auto py-16 px-4">
                <div className='max-w-[1180px] mx-auto'>

                    <h2 className="text-4xl font-bold mb-4 text-[#262626]">{
                        article.title[language.toLowerCase()]
                    }</h2>
                    <hr className="border-2 border-[#969696] my-10 w-10/12" />
                    <div className="flex-col-reverse flex md:flex-row">
                        <div className="w-full md:w-1/2 pr-8">
                            <p className='text-[20px] font-bold uppercase text-[#262626] mb-6 leading-[30px]'>
                                СФЕРА: {article.categories[language.toLowerCase()].join(', ')}
                            </p>
                            {parse(article.content[language.toLowerCase()])}
                        </div>
                        <div className="w-full md:w-1/2">
                            <img src={article.image} alt={article.title[language.toLowerCase()]} className="w-full" />
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Articles;