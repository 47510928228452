const TextBlockComponent = ({
  text = `Наша ціль – ініціювати змінотворчі процеси в суспільстві
  на прикладі комунікаційної сфери, де зусилля маленьких
  спільнот стають основою для державних перетворень, де
  різноманітність досвіду, зокрема цивільного і бойового,
  створює нові можливості, де громада працює на благо
  одного і кожен відчуває ресурс і підтримку групи.`
}) => {
  return (


    <div className="bg-[#5b19c3] text-white text-center py-24">
      <div className="container mx-auto">
        <div className='max-w-[1180px] mx-auto'>
          <p className="leading-relaxed font-bold text-xl text-[#6beef1] italic max-w-6xl mx-auto px-2">
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TextBlockComponent;
