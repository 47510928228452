import parse from 'html-react-parser';


const PhotoTextBlock = ({
    title,
    content,
    img,
    reverse = false
}) => {
    console.log(reverse)
    return (
        <div className={`flex flex-col  ${reverse ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
            <div className="w-full md:w-[55%]">
                <img
                    src={img} // Укажите путь к вашему изображению здания
                    alt="Building"
                    className="w-full h-[425px] object-cover"
                />
            </div>
            <div className={`w-full max-w-[500px] md:w-[45%] px-8 ${reverse ? 'md:pr-[50px]' : 'md:pl-[50px]'} py-8 md:py-0 flex flex-col items-baseline justify-center`}>
                <h2 className="text-4xl font-bold mb-4 text-[#262626]">{title}</h2>
                <div>
                    {parse(content)}
                </div>
            </div>
        </div>
    );
};

export default PhotoTextBlock;
