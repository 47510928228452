const ImageTextCard = ({ imageSrc, altText, title, content, float = 'right' }) => {

return (<div className={`container mx-auto auto max-w-[1180px] py-4`}>
        <div className={`flex items-center bg-white flex-col ${
        float === 'right' ? 'md:flex-row' : 'md:flex-row-reverse'
        }`}>
            <div className="w-full md:w-1/2"><img
                src={imageSrc}
                alt={altText}
                className="mx-auto object-cover h-[360px]" // Задайте ширину изображения в соответствии с вашими требованиями
            />
            </div>
            <div className="w-full md:w-1/2 p-4 md:pl-[30px]">
                <h2 className="text-[36px] font-bold mb-4 text-[#262626] leading-[43.2px]">{title}</h2>
                <p className="mb-8 w-full text-[16px] text-[#262626] leading-[24px] font-[500]">{content}</p>
            </div>
        </div>
    </div>);
};

export default ImageTextCard;
