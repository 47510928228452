import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import translations from '../langs';


const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
    // Get default value from localStorage or set 
    const location = useLocation();
    const navigate = useNavigate();
    const defaultLanguage = location.pathname.includes('/en') ? 'EN' : 'UA';
    const [language, setLanguage] = useState(localStorage.getItem('appLanguage') || defaultLanguage);
    const currentTranslations = translations[language];

    useEffect(() => {
        // Устанавливаем язык в зависимости от URL при монтировании компонента
        const langFromPath = location.pathname.includes('/en') ? 'EN' : 'UA';
        if (langFromPath !== language) {
            setLanguage(langFromPath);
        }
        // Слушаем изменения location
    }, [location]);



    const getNestedTranslation = (obj, path) => {
        return path.split('.').reduce((xs, x) => (xs && xs[x] !== undefined ? xs[x] : null), obj);
    };

    // Function for receiving translation with automatic fallback into Ukrainian
    const getTranslation = (path) => {
        const value = getNestedTranslation(currentTranslations, path);
        // If the value is not found, use Ukrainian as a fallback
        return value !== null ? value : getNestedTranslation(translations.UA, path);
        // return getNestedTranslation(translations.UA, path); //
    };

    const toggleLanguage = () => {
        setLanguage((prevLanguage) => {
            const newLanguage = prevLanguage === 'EN' ? 'UA' : 'EN';
            localStorage.setItem('appLanguage', newLanguage); // Update localStorage
            // Обновляем URL при смене языка
            navigate(newLanguage === 'EN' ? '/en' : '/');
            return newLanguage;
        });
    };

    // Effect localStorage first load
    useEffect(() => {
        localStorage.setItem('appLanguage', language);
    }, [language]);

    // change metategs function from Metatags.js
    useEffect(() => {
        const meta = document.querySelector('meta[name="description"]');
        meta.setAttribute('content', getTranslation('Metatags.description'));

        const title = document.querySelector('title');
        title.innerHTML = getTranslation('Metatags.title');

        //name="title"
        const titleName = document.querySelector('meta[name="title"]');
        titleName.setAttribute('content', getTranslation('Metatags.title'));

        const keywords = document.querySelector('meta[name="keywords"]');
        keywords.setAttribute('content', getTranslation('Metatags.keywords'));

        const lang = document.querySelector('html');
        lang.setAttribute('lang', language.toLowerCase());

        // og:title
        const ogTitle = document.querySelector('meta[property="og:title"]');
        ogTitle.setAttribute('content', getTranslation('Metatags.title'));

        // og:description
        const ogDescription = document.querySelector('meta[property="og:description"]');
        ogDescription.setAttribute('content', getTranslation('Metatags.description'));


    }, [language]);

    return (
        <LanguageContext.Provider value={{
            language: language, // just language,
            translations: translations[currentTranslations], // currentTranslations,
            toggleLanguage,
            getTranslation: getTranslation,
        }}>
            {children}
        </LanguageContext.Provider>
    );
};
