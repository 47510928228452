import scrollToElement from '../../utils/ScrollTo';

const InfoBlock = () => {
    return (
        <div className="container mx-auto p-8">
            <div className='max-w-[1180px] mx-auto'>
                <h2 className="text-4xl font-bold mb-4 text-[#262626]">Місія</h2>
                <p className="text-[20px] font-medium text-[#262626] mb-6 leading-[30px]">
                    Ми розуміємо, що кожен відповідальний бізнес повинен стати компонентом ветеранської політики,
                    створюючи освітні можливості, нові робочі місця, а головне – безпечний і комфортний
                    простір для захисників України, які повертаються до цивільного життя.
                </p>
                <p className="text-[20px] mb-8 font-medium text-[#262626] leading-[30px]">
                    У контексті широкої загальнонаціональної дискусії щодо реінтеграції військових у соціально-економічний 
                    простір країни dentsu Ukraine впроваджує кар’єрну програму для військовослужбовців, ветеранів і їхніх сімей. 
                    Ініціатива передбачає навчання, профорієнтацію і стажування у рекламно-комунікаційній індустрії.
                </p>
                <div className="flex flex-wrap gap-4">
                    <button className="bg-[#00c9cf] font-bold text-white py-4 px-10 mr-4 hover:bg-opacity-80 duration-200" onClick={() => scrollToElement('veterans')}>Учасникам</button>
                    <button className="bg-[#00c9cf] font-bold text-white py-4 px-10 hover:bg-opacity-80 duration-200" onClick={() => scrollToElement('businesses')}>Бізнесам</button>
                </div>
            </div>
        </div>
    );
};

export default InfoBlock;
