
import React, {
    useEffect,
    useState
} from 'react';
import Slider from 'react-slick';
import { ArrowLeft, ArrowRight } from './icons/Arrows';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../contexts/LanguageContext';
import articlesData from '../../news/news.json';

// Компонент для кастомной стрелки "вперед"
const NextArrow = ({ onClick }) => (
    <div className="slick-arrow custom-next-arrow cursor-pointer" onClick={onClick}>
        {/* Здесь должен быть ваш SVG код для стрелки "вперед" */}
        {/* Пример: <ArrowRight className="your-custom-class" /> */}
        <ArrowRight width="24px" />
    </div>
);

// Компонент для кастомной стрелки "назад"
const PrevArrow = ({ onClick }) => (
    <div className="slick-arrow custom-prev-arrow cursor-pointer" onClick={onClick}>
        {/* Здесь должен быть ваш SVG код для стрелки "назад", возможно, с переворотом */}
        {/* Пример: <ArrowRight className="your-custom-class rotate-180" /> */}
        <ArrowLeft width="24px" onClick={onClick} />
    </div>
);

export const Card = ({ image, title, description, category, count, company }) => {
    return (
        <div className="m-2 shadow-lg bg-white">
            <img src={image} alt={title} className=" mb-4 max-h-[235px] w-full object-cover" />

            <div className="bg-white p-4">
                <h3 className="text-2xl text-[#262626] font-bold mb-2">{title}</h3>
                <p className="text-[16px] leading-[24px] text-[#262626] font-medium mb-4 line-clamp-5 md:h-[122px]">
                    {description}
                </p>
                <div className="flex items-center text-[16px] leading-[24px] text-[#262626] mb-4 gap-2 uppercase">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-purple-600 w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                    </svg>
                    {category}
                </div>
                <div className="flex items-center justify-between">

                    {count &&<div className="flex items-center gap-2 text-[16px] leading-[24px] text-[#262626]">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-purple-600 w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                        </svg>

                        {count}
                    </div>}
                    <p className="text-orange-500 font-bold m-0">
                        <img src={`/media/practice/logos/${company}.png`} alt={company} className="w-auto h-4 object-cover" />
                    </p>

                </div>
            </div>
        </div>
    );
};

const BusinessPracticesSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const [article, setArticle] = useState(null);
    const { language } = useLanguage();
    useEffect(() => {
        // Найти статью по slug
        const foundArticle = articlesData.articles;
        setArticle(foundArticle);
    }, []);

    return (
        <div className="px-4 py-14 bg-gray-200">
            <div className='container max-w-[1180px] mx-auto'>
                <div className='flex flex-col space-y-8 md:space-y-0 md:flex-row justify-between items-center mb-6'>
                    <h2 className="text-4xl ml-2 font-bold text-[#262626] ">Практики у бізнесі</h2>
                    <Link preventScrollReset={true} to={'/add-case'} className="bg-[#00c9cf] font-bold text-white py-4 px-6 hover:bg-opacity-80 duration-200">Додати кейс</Link>
                </div>
                <Slider {...settings}>
                    {/* Карточки заполнены данными для примера */}
                    {article && article.map((item) => <Link to={`/news/${item.slug}`} key={item.slug}>
                        <Card
                            image={item.image}
                            title={item.title[language.toLowerCase()]}
                            description={item.shortContent[language.toLowerCase()]}
                            category={item.categories[language.toLowerCase()].join(', ')}
                            count=""
                            company=""
                        />
                    </Link>)}
                    {/* 
                        < Card
                        image="/media/practice/practice_2.jpg"
                    title="Aenean"
                    description="Morbi enim mi, tincidunt id erat malesuada, mattis feugiat augue. Phasellus a iaculis ante, at hendrerit nunc."
                    category="Фгенції"
                    count="2000"
                    company="dentsu"
                    />

                    <Card
                        image="/media/practice/practice_3.jpg"
                        title="Vivamus"
                        description="Sed vehicula orci ut mi porttitor, et vehicula arcu placerat. Etiam ultrices pulvinar neque quis faucibus."
                        category="IT продуктові"
                        count="60"
                        company="isobar"
                    /> */}
                    {/* Добавьте больше <Card /> компонентов с различными пропсами */}
                </Slider>
            </div>
        </div>
    );
};

export default BusinessPracticesSlider;
