import React, { useState } from 'react';
import Modal from './Modal';

const DownloadGuideBlock = () => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    position: '',
    company: '',
    email: '',
    consent: false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleInputChange = (e) => {
    const { id, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: type === 'checkbox' ? checked : value,
    }));
  };

  const isFormValid = () => {
    const { name, surname, position, company, email, consent } = formData;
    return name && surname && position && company && email && consent;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Тут можна додати логіку для обробки даних форми, наприклад, завантаження файлу
    //actions/formWIthFiles.php
    console.log('sent')
    try {
      const response = await fetch('/actions/formWIthFiles.php', {
        method: 'POST',
        body: new FormData(e.target),
      });
      const data = await response.json();
      // download file /GUIDE_Veterans_Marketing_Isobar.pdf
      window.location.href = '/GUIDE_Veterans_Marketing_Isobar.pdf';
      console.log(data);
    } catch (e) {
      console.error(e);
    }

    // Після завантаження файлу показуємо модальне вікно
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false); // Закрити модальне вікно
  };
  return (<div className="bg-gray-200">
    <div className="container mx-auto auto max-w-[1180px] px-4 py-10">
      <h2 className="text-4xl font-bold mb-4 text-[#262626] max-w-md">Гайд з ветеранського маркетингу</h2>

      <div className="flex flex-col md:flex-row">
        <div className="flex-1 flex flex-col justify-between">
          <img
            src="/media/veterans_marketing/card_2.jpg" // замените на путь к вашему изображению
            alt="Ветераны с флагом"
            className="max-h-[360px] mb-4"
          />
          <p className="text-[16px] font-medium text-[#262626] mb-6 leading-[24px] ">
            Для кого цей матеріал: для брендів і бізнесів, які гарячуть знати, як комунікувати з нового ЦА.
            Від кого: від досвідчених комунікаторів, які день у день допомагають брендам у чутом.
          </p>

        </div>
        <div className="flex-1 ml-0 md:ml-8">
          <form onSubmit={handleSubmit} className="flex flex-col max-w-xs">
            <input
              type="text"
              id="name"
              placeholder="Ваше ім'я*"
              name='name'
              className="mb-4 p-2 border-gray-800 border-b bg-transparent outline-0"
              value={formData.name}
              onChange={handleInputChange}
            />

            <input
              type="text"
              id="surname"
              name='surname'
              placeholder="Ваше прізвище*"
              className="mb-4 p-2 border-gray-800 border-b bg-transparent outline-0"
              value={formData.surname}
              onChange={handleInputChange}
            />

            <input
              type="text"
              id="position"
              name='position'
              placeholder="Посада*"
              className="mb-4 p-2 border-gray-800 border-b bg-transparent outline-0"
              value={formData.position}
              onChange={handleInputChange}
            />

            <input
              type="text"
              id="company"
              name='company'
              placeholder="Компанія*"
              className="mb-4 p-2 border-gray-800 border-b bg-transparent outline-0"
              value={formData.company}
              onChange={handleInputChange}
            />

            <input
              type="email"
              id="email"
              name='email'
              placeholder="E-mail*"
              className="mb-4 p-2 border-gray-800 border-b bg-transparent outline-0"
              value={formData.email}
              onChange={handleInputChange}
            />

            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="consent"
                name='consent'
                className="mr-2"
                checked={formData.consent}
                onChange={handleInputChange}
              />
              <label htmlFor="consent">Я погоджуюсь з обробкою моїх персональних даних</label>
            </div>

            <button
              type="submit"
              className={`bg-[#00c9cf] font-bold text-white py-4 px-6 hover:bg-opacity-80 duration-200 ${!isFormValid() && 'opacity-50 cursor-not-allowed'}`}
              disabled={!isFormValid()}
            >
              Завантажити файл
            </button>
          </form>
        </div>
      </div>
    </div>
    <Modal isVisible={isModalVisible} onClose={handleCloseModal}>
      <p>Файл успішно завантажено!</p>
      <p>Дякуємо за Ваш запит.</p>
      {/* Тут може бути будь-який інший контент, який ви хочете показати у модальному вікні */}
    </Modal>
  </div>);
};

export default DownloadGuideBlock;
