import { useLocation } from "react-router-dom";

const HeroSection = ({
    backgroundImage = '/media/veteranup.jpg',
    title = 'Veterans UP',
    subtitle = "Кар'єрний проєкт для військових, ветеранів і їхніх сімей у рекламній сфері",
}) => {

    // check if page is main by url "/"
    const location = useLocation();

    console.log(location.pathname)
    const headerHeight = 70;
    return (
        <div
            className="relative main-header bg-no-repeat bg-cover bg-center bg-center text-center py-10 lg:min-h-1/3 flex flex-col justify-center items-center"
            style={{ backgroundImage: `url(${backgroundImage})`, minHeight: `calc(100vh - ${headerHeight}px)` }}
        >
            <div className="w-full md:w-3/5">
                <h1 className="text-white text-5xl sm:text-8xl font-bold mb-4">{title}</h1>
                <p className="text-white font-semibold text-4xl mt-4 px-2">{subtitle}</p>
            </div>
           {location.pathname === '/' && <div className="absolute bottom-8 flex items-end justify-center gap-3 flex-col">
                <hr className="border-white border-2 w-full" />
                <div className="flex items-end justify-center gap-8 px-2 md:px-8">
                    <img src="/media/logos/dentsu.png" alt="scroll down" className="w-auto h-[20px] mx-auto" />
                    <img src="/media/logos/isobar.png" alt="scroll down" className="w-auto h-[20px] mx-auto" />
                    <img src="/media/logos/iprospect.png" alt="scroll down" className="w-auto h-[15px] mx-auto" />
                </div>
            </div>}
        </div>
    );
};

export default HeroSection;
