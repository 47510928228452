import { Link } from "react-router-dom";
import { ArrowRight } from "../icons/Arrows";

export const ServicesCard = ({ title, description, image, link }) => {

    return <>
        <img
            src={image} // Укажите путь к вашему изображению
            alt={title}
            className="rounded-full mb-4 mx-auto object-cover aspect-square w-[35%] md:w-[75%]"

        />
        <h3 className="text-2xl font-bold text-[#262626] leading-[29px]">{title}</h3>
        <p className="my-3 text-lg text-[#262626] leading-[27px] font-medium">
            {description}
        </p>
       {link && <Link to={link} className="flex justify-center gap-2 font-bold text-[#00cacf] text-xl mt-auto">Дізнатися більше <ArrowRight width="24px" variant="outlined" /></Link>}
    </>
}
