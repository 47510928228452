import React from 'react';

import Slider from 'react-slick';
import { ArrowLeft, ArrowRight } from './icons/Arrows';

// Компонент для кастомной стрелки "вперед"
const NextArrow = ({ onClick }) => (
    <div className="slick-arrow custom-next-arrow cursor-pointer" style={{ right: '25px' }} onClick={onClick}>
        {/* Здесь должен быть ваш SVG код для стрелки "вперед" */}
        {/* Пример: <ArrowRight className="your-custom-class" /> */}
        <ArrowRight width="24px" fill='#00c9cf' />
    </div>
);

// Компонент для кастомной стрелки "назад"
const PrevArrow = ({ onClick }) => (
    <div className="slick-arrow custom-prev-arrow cursor-pointer" style={{ left: '25px' }} onClick={onClick}>
        {/* Здесь должен быть ваш SVG код для стрелки "назад", возможно, с переворотом */}
        {/* Пример: <ArrowRight className="your-custom-class rotate-180" /> */}
        <ArrowLeft width="24px" onClick={onClick} fill='#00c9cf' />
    </div>
);

const SliderNonContainer = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1.5, // Показывать немного больше одного слайда
        slidesToScroll: 1,
        adaptiveHeight: true, // Set adaptiveHeight to true to make all slides the same height
        // centerMode: true, // Включить центральный режим
        // centerPadding: '0px', // Отступы по бокам центрального слайда
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    return (
        <div className='container mx-auto auto max-w-[1180px] my-4 h-[760px] sm:h-[600px] md:h-[360px]'>
            <div className='grid grid-cols-1 md:grid-cols-2 items-center h-[360px]'>
                <div className="p-4 md:pl-[30px">
                    <h2 className="text-[36px] leading-[43.2px] font-bold mb-4 text-[#262626]">Консалтинг</h2>
                    <p className="text-[16px] font-medium text-[#262626] mb-6 leading-[24px]">
                        Якщо вам цікаво дізнатися більше про нові норми маркетингової комунікації, які виникли в Україні у зв’язку з війною, звертайтеся за індивідуальною консультацією або запитом на публічний виступ.
                    </p>
                </div>
                <div className="slider-container relative md:absolute md:right-0 md:w-1/2 xl:w-1/2">
                    <Slider {...settings}>
                        <div className="px-2 focus:outline-none">
                            <img src="/media/veterans_marketing/slider/IMG_8197.jpg" alt="Marketing" className="object-cover w-full h-[360px]" />
                        </div>
                        <div className="px-2 focus:outline-none">
                            <img src="/media/veterans_marketing/slider/IMG_8198.jpg" alt="Marketing" className="object-cover w-full h-[360px]" />
                        </div>
                        <div className="px-2 focus:outline-none">
                            <img src="/media/veterans_marketing/slider/IMG_8215.jpg" alt="Marketing" className="object-cover w-full h-[360px]" />
                        </div>

                        {/* <div>
                            <img style={{
                                height: '360px',
                                width: '240px'
                            }} src="/media/veterans_marketing/slider/IMG_8197.jpg" alt="Marketing" />
                        </div>
                        <div>
                            <img style={{
                                height: '360px',
                                width: '240px'
                            }} src="/media/veterans_marketing/slider/IMG_8198.jpg" alt="Marketing" />
                        </div>
                        <div>
                            <img style={{
                                height: '360px',
                                width: '240px'
                            }} src="/media/veterans_marketing/slider/IMG_8215.jpg" alt="Marketing" />
                        </div> */}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default SliderNonContainer;
