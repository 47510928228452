export const ArrowRight = ({ width = 'auto', height = 'auto', fill = 'none', full = true }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 95.55 122.82"
        style={{ width, height }}
    >

        <defs>
            <style>
                {
                    full ? `
                    .cls-2 { clip-path: url(#clip-path); }
                    .cls-3 { stroke: #00cacf; stroke-miterlimit: 10; stroke-width: 5.15px; }
                  ` : `
                    .cls-2 { clip-path: url(#clip-path); }
                    .cls-3 { fill: #00cacf; stroke: #00cacf; stroke-miterlimit: 10; stroke-width: 5.15px; }
                    `
                }
            </style>
            <clipPath id="clip-path" transform="translate(0 0)">
                <rect className="cls-1" width="95.55" height="122.82" />
            </clipPath>
        </defs>
        <g className="cls-2" style={{ fill: fill }} >
            <polygon
                className="cls-3"
                points="34.14 3.64 3.64 34.14 30.91 61.41 3.64 88.68 34.14 119.18 63.02 90.29 91.91 61.41 63.02 32.53 61.41 34.14 63.02 32.53 34.14 3.64"
            />
        </g>
    </svg>
);

export const ArrowLeft = ({ width = 'auto', height = 'auto', fill = 'none', full = true }) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 95.55 122.82" style={{ width, height, rotate: '180deg' }}>
        <defs>
            <style>
                {
                    full ? `
                    .b { clip-path: url(#clip-path); }
                    .c { stroke: #00cacf; stroke-miterlimit: 10; stroke-width: 5.15px; }
                  ` : `
                    .b { clip-path: url(#clip-path); }
                    .c { fill: #00cacf; stroke: #00cacf; stroke-miterlimit: 10; stroke-width: 5.15px; }
                    `
                }
            </style>
            <clipPath id="a" transform="translate(0 0)">
                <rect className="a" width="95.55" height="122.82" fill={fill}></rect>
            </clipPath>
        </defs>
        <title>arrow</title>
        <g className="b" style={{ fill: fill }} >
            <polygon className="c" points="34.14 3.64 3.64 34.14 30.91 61.41 3.64 88.68 34.14 119.18 63.02 90.29 91.91 61.41 63.02 32.53 61.41 34.14 63.02 32.53 34.14 3.64"></polygon>
        </g>
    </svg>
);
