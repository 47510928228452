import '../../../App.css';
import Main from '../../components/Main';
import TextBlockComponent from '../../components/TextBlockComponent';
import JustTextBlock from '../../components/JustTextBlock';
import ImageTextCard from '../../components/ImageTextCard';
import SliderNonContainer from '../../components/SliderNonContainer';
import DownloadGuideBlock from '../../components/DownloadGuideBlock';


function VeteransMarketing() {

    return (<div>

        <Main
                backgroundImage="/media/bz-pr-bg.jpg"
                title='Маркетинг поваги'
            subtitle='Бренди зіштовхнулись і надалі будуть
        зіштовхуватися із необхідністю адаптовувати
        комунікацію до нових реалій'
        />

        <JustTextBlock
            title='Що таке ветеранський маркетинг?'
            content={`<p className="text-[20px] font-medium text-[#262626] mb-6 leading-[30px]">
            Це умовне позначення корпоративних та брендових комунікацій, розроблених з урахуванням глибокого розуміння запитів, потреб та вподобань військовослужбовців, ветеранської спільноти та їхніх сімей.
        </p>`} />
        <TextBlockComponent
            text={`За різними оцінками, статус ветерана чи ветеранки матимуть близько 10-12% населення. Разом з близькими та родичами спільнота може зрости у 2-3 рази.`} />

        <ImageTextCard
            imageSrc='/media/veterans_marketing/card_1.jpg'
            altText='Відеолекція'
            title='Відеолекція'
            content={`Стратеги диджитал агенції Isobar Ukraine Володимир Никоненко і Марина Костіна розкривають основи ветеранського маркетингу та діляться практичними порадами, як зробити комунікацію зрозумілою для ветеранської спільноти, а також уникнути помилок та непорозумінь.`}
        />


        <SliderNonContainer />

        <DownloadGuideBlock />
    </div>);
}

export default VeteransMarketing;
