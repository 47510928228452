import React from 'react';

function Modal({ isVisible, onClose, children }) {
    if (!isVisible) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
        }}>
            <div className='relative p-[34px]' style={{
                background: '#fff',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '500px',
                maxHeight: '80%',
                overflowY: 'auto',
            }}>
                {children}
                <button onClick={onClose} className='absolute text-red-500 right-[10px] top-[10px]' style={{
                    cursor: 'pointer',
                }}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default Modal;
